var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticStyle:{"top":"8px"},attrs:{"maskClosable":false,"title":"选择需求单商品","width":"80%"},on:{"ok":_vm.toSubmit},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"query"},[_c('a-form-model',{attrs:{"labelCol":{ span: 7 },"wrapperCol":{ span: 17 }}},[_c('a-row',{attrs:{"gutter":4}},[_c('a-col',{attrs:{"md":8}},[_c('a-form-model-item',{attrs:{"label":"商品名称"}},[_c('a-input',{attrs:{"placeholder":"请输入商品名称","max-length":50},model:{value:(_vm.queryParam.productName),callback:function ($$v) {_vm.$set(_vm.queryParam, "productName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryParam.productName"}})],1)],1),_c('a-col',{attrs:{"md":8}},[_c('a-form-model-item',{attrs:{"labelCol":{ span: 0 },"wrapperCol":{ span: 22, offset: 1 }}},[_c('a-button',{attrs:{"type":"primary","icon":"search"},on:{"click":function($event){return _vm.toSearch(0)}}},[_vm._v("查询")]),_c('a-button',{attrs:{"type":"default","icon":"sync"},on:{"click":function($event){return _vm.toSearch(1)}}},[_vm._v("重置")])],1)],1)],1)],1)],1),_c('div',{staticClass:"table"},[_c('a-table',{attrs:{"data-source":_vm.tableData,"bordered":"","rowKey":function (record) { return record.id; },"pagination":_vm.page,"row-selection":{
        fixed: true,
        type: 'radio',
        onChange: function (keys, rows) {
          _vm.selectedRowKeys = keys
          _vm.selectedRows = rows
        },
      },"customRow":function (record) {
          return {
            on: {
              click: function (e) {
                var index = this$1.selectedRowKeys.indexOf(record.id)
                index >= 0 ? _vm.selectedRowKeys.splice(index, 1) : _vm.selectedRowKeys.push(record.id)
                index >= 0 ? _vm.selectedRows.splice(index, 1) : _vm.selectedRows.push(record)
              },
            },
          }
        }},on:{"change":_vm.changeTable}},[_c('a-table-column',{attrs:{"title":"商品名称","data-index":"name","width":300,"ellipsis":true,"align":"center"}}),_c('a-table-column',{attrs:{"title":"分类","data-index":"categoryName","ellipsis":true,"align":"center"}}),_c('a-table-column',{attrs:{"title":"品牌","data-index":"brandName","ellipsis":true,"align":"center"}}),_c('a-table-column',{attrs:{"title":"建议零售价","data-index":"price","ellipsis":true,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(_vm._s(text.toFixed(2)))]}}])}),_c('a-table-column',{attrs:{"title":"标准开票价（D价）","data-index":"sellPrice","width":150,"ellipsis":true,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(_vm._s(text.toFixed(2)))]}}])}),_c('a-table-column',{attrs:{"title":"单位","data-index":"proUnitName","width":150,"ellipsis":true,"align":"center"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }