<template>
  <a-modal :maskClosable="false" title="选择需求单商品" style="top: 8px" width="80%" v-model="visible" @ok="toSubmit">
    <div class="query">
      <a-form-model :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }">
        <a-row :gutter="4">
          <a-col :md="8">
            <a-form-model-item label="商品名称">
              <a-input v-model.trim="queryParam.productName" placeholder="请输入商品名称" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8">
            <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
              <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
              <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="table">
      <a-table
        :data-source="tableData"
        bordered
        :rowKey="record => record.id"
        :pagination="page"
        @change="changeTable"
        :row-selection="{
          fixed: true,
          type: 'radio',
          onChange: (keys, rows) => {
            selectedRowKeys = keys
            selectedRows = rows
          },
        }"
        :customRow="
          (record) => {
            return {
              on: {
                click: (e) => {
                  let index = this.selectedRowKeys.indexOf(record.id)
                  index >= 0 ? selectedRowKeys.splice(index, 1) : selectedRowKeys.push(record.id)
                  index >= 0 ? selectedRows.splice(index, 1) : selectedRows.push(record)
                },
              },
            }
          }
        "
      >
        <a-table-column title="商品名称" data-index="name" :width="300" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="分类" data-index="categoryName" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="品牌" data-index="brandName" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="建议零售价" data-index="price" :ellipsis="true" align="center">
          <template slot-scope="text">{{text.toFixed(2)}}</template>
        </a-table-column>
        <a-table-column title="标准开票价（D价）" data-index="sellPrice" :width="150" :ellipsis="true" align="center">
          <template slot-scope="text">{{text.toFixed(2)}}</template>
        </a-table-column>
        <a-table-column title="单位" data-index="proUnitName" :width="150" :ellipsis="true" align="center">
        </a-table-column>
      </a-table>
    </div>
  </a-modal>
</template>
<script>
const qs = require('qs')
export default {
  data() {
    return {
      queryParam: {},
      resetQueryParam: null,
      tableData: [],
      loading: false,
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      visible: false,
      FlagDealer: 1
    }
  },
  components: {},
  methods: {
    show(ids, FlagDealer) {
      this.visible = true
      this.selectedRows = []
      this.FlagDealer = FlagDealer
      this.toSearch(1)
    },

    toSearch(type) {
      if (type == 1) {
        this.resetQueryParam
          ? (this.queryParam = Object.assign({}, this.resetQueryParam))
          : (this.resetQueryParam = Object.assign({}, this.queryParam))
      }
      const postData = Object.assign(this.queryParam, {
        FlagDealer: this.FlagDealer == 1 ? false : true,
        pageNumber: type == 2 ? this.page.current : (this.page.current = 1),
        pageSize: this.page.pageSize,
      })
      this.axios.get(`/api/product/system/goods/listByOrder?${qs.stringify(postData)}`)
        .then((res) => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        })
        .catch((err) => {})
    },

    // 分页
    changeTable(pagination) {
      this.page = pagination
      this.toSearch(2)
    },

    toSubmit() {
      if (this.selectedRows.length <= 0) {
        this.$message.warning('请至少选择一条商品')
        return
      }
      this.$emit('select', this.selectedRows)
      this.visible = false
    },
   
  },
  created() {},
}
</script>


<style lang="less" scoped>
</style>
